import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SvgRoyal from "@icon/icofont/icons/royal.svg";
import SvgOpposite from "@icon/icofont/icons/opposite.svg";
import SvgFootPrint from "@icon/icofont/icons/foot-print.svg";
import SvgHeart from "@icon/icofont/icons/heart.svg";
import SvgHoldingHands from "@icon/icofont/icons/holding-hands.svg";
import SvgTreeAlt from "@icon/icofont/icons/tree-alt.svg";
import ScrollAnimation from "../ScrollAnimation";


const Values: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    headlines {
                        adorare,
                        rascumparare,
                        krestere,
                        afectiune,
                        ajutorare,
                        ucenicie
                    }
                }
            }
        }
    `);

    return (
        <>
            <section className="values-area section-pattern section-padding-100-70" id="valori">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="section-heading">
                                <p>Care sunt valorile noastre?</p>
                                <h3>Valori</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-6 order-lg-2 mb-5">
                            <ScrollAnimation animateIn="fadeInRight" >
                                <div className="image-bordered">
                                    <StaticImage
                                        src="../../images/arka-board.jpg"
                                        alt="Valori"
                                    />
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-lg-6">
                            <ScrollAnimation animateIn="fadeInLeft" delay={1}>
                                <div className="values-item">
                                    <h4><Link to="/adorare" title="Adorarea">Adorarea</Link></h4>
                                    <p>{data.site.siteMetadata.headlines.adorare}</p>
                                    <div className="icon-container"><SvgRoyal /></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInLeft" delay={1}>
                                <div className="values-item">
                                    <h4><Link to="/rascumparare" title="Răscumpararea">Răscumpararea</Link></h4>
                                    <p>{data.site.siteMetadata.headlines.rascumparare}</p>
                                    <div className="icon-container"><SvgOpposite /></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInLeft" delay={1}>
                                <div className="values-item">
                                    <h4><Link to="/krestere" title="Kreșterea">Kreșterea</Link></h4>
                                    <p>{data.site.siteMetadata.headlines.krestere}</p>
                                    <div className="icon-container"><SvgFootPrint /></div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-6 mb-5">
                            <ScrollAnimation animateIn="fadeInLeft" >
                                <div className="image-bordered">
                                    <StaticImage
                                        src="../../images/valori.jpg"
                                        alt="Valori"
                                    />
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-lg-6">
                            <ScrollAnimation animateIn="fadeInRight" delay={1}>
                                <div className="values-item">
                                    <h4><Link to="/afectiune" title="Afecțiunea">Afecțiunea</Link></h4>
                                    <p>{data.site.siteMetadata.headlines.afectiune}</p>
                                    <div className="icon-container"><SvgHeart /></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInRight" delay={1}>
                                <div className="values-item">
                                    <h4><Link to="/afectiune" title="Ajutorarea">Ajutorarea</Link></h4>
                                    <p>{data.site.siteMetadata.headlines.ajutorare}</p>
                                    <div className="icon-container"><SvgHoldingHands /></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInRight" delay={1}>
                                <div className="values-item">
                                    <h4>Ucenicia</h4>
                                    <p>{data.site.siteMetadata.headlines.ucenicie}</p>
                                    <div className="icon-container"><SvgTreeAlt /></div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Values;
