import React, { FC, useContext, useEffect, useRef } from "react";
import classnames from "classnames";
import { AppContext } from "../context/AppContext";

type PropsType = {
    children: React.ReactNode;
    animateIn?: string;
    delay?: number;
    visible?: boolean;
};

const ScrollAnimation: FC<PropsType> = ({ children, animateIn, delay, visible }: PropsType) => {
    const elementRef = useRef<HTMLDivElement>();
    const isAnimatedRef = useRef(visible ?? false);

    const { scrollOffset } = useContext(AppContext);

    // set default values
    animateIn = animateIn || "fadeInUp";
    delay = delay || 0;

    const animate = () => {
        if (elementRef.current == null || isAnimatedRef.current == null) return;
        if (isAnimatedRef.current === true) return;

        const elementOffset = elementRef.current.getBoundingClientRect();
        // check if element is partially visible
        if (elementOffset.top < window.innerHeight && elementOffset.bottom >= 0) {
            isAnimatedRef.current = true;
        }
    };

    useEffect(() => {
        animate();
    }, [scrollOffset]);

    return (
        <div
            ref={elementRef}
            className={classnames({
                [`visible animate__animated animate__${animateIn}`]: isAnimatedRef.current,
                [`animate__delay-${delay}s`]: delay > 0,
                "invisible": !isAnimatedRef.current,
            })}
        >
            {children}
        </div>
    );
};

export default ScrollAnimation;
