import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import Slider from "../components/home/Slider";
import AboutUs from "../components/home/AboutUs";
import Video from "../components/home/Video";
import Values from "../components/home/Values";
import History from "../components/home/History";
import Program from "../components/home/Program";
import Map from "../components/home/Map";
import { Helmet } from "react-helmet";

const title = "Acasă";

const IndexPage: FC = () => (
    <Layout>
        <Helmet title={title} />

        <Slider />
        <AboutUs />
        <Video />
        <Values />
        <History />
        <Program />
        <Map />
    </Layout>
);

export default IndexPage;
