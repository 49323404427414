import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Carousel } from "react-responsive-carousel";

const Slider: FC = () => {
    return (
        <>
            <section id="acasa">
                <Carousel
                    showArrows={false} showStatus={false} showIndicators={false} showThumbs={false} dynamicHeight={false}
                    autoPlay={true} infiniteLoop={true} interval={5000} stopOnHover={false} emulateTouch={true} swipeScrollTolerance={100}
                >
                    <div>
                        <StaticImage
                            src="../../images/adorare.jpg"
                            alt="Adorare"
                        />
                        <p className="legend"><span>A</span>dorare</p>
                    </div>
                    <div>
                        <StaticImage
                            src="../../images/rascumparare.jpg"
                            alt="Răscumpărare"
                        />
                        <p className="legend"><span>R</span>ăscumpărare</p>
                    </div>
                    <div>
                        <StaticImage
                            src="../../images/krestere.jpg"
                            alt="Kreștere"
                        />
                        <p className="legend"><span>K</span>reștere</p>
                    </div>
                    <div>
                        <StaticImage
                            src="../../images/afectiune.jpg"
                            alt="Afecțiune &amp; Ajutorare"
                        />
                        <p className="legend"><span>A</span>fecțiune &amp; <span>A</span>jutorare</p>
                    </div>
                </Carousel>
            </section>
            <div className="clearfix"></div>
        </>
    );
};

export default Slider;
