import React, { FC } from "react";
import SvgPlay from "@icon/icofont/icons/play.svg";
import ScrollAnimation from "../ScrollAnimation";
import { graphql, useStaticQuery } from "gatsby";

const Video: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className="video-area text-center section-padding-200" data-stellar-background-ratio="0.6">
            <div className="video-play-area">
                <ScrollAnimation animateIn="fadeInUp">
                    <h3>{data.site.siteMetadata.title}</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={1}>
                    <p>Cunoaște-ne mai bine</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={2}>
                    <a href="https://www.youtube.com/watch?v=ZweHM0cOO5o" title="Cunoaște-ne mai bine" className="video-btn" target="_blank" rel="noopener noreferrer">
                        <SvgPlay />
                    </a>
                </ScrollAnimation>
            </div>
        </div>
    );
};

export default Video;
