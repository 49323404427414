import React, { FC } from "react";
import SvgHome from "@icon/icofont/icons/home.svg";
import SvgPeople from "@icon/icofont/icons/people.svg";
import ScrollAnimation from "../ScrollAnimation";

const History: FC = () => (
    <section className="section-default section-padding-100" id="istorie">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="section-heading">
                        <p>Pietre de aducere aminte</p>
                        <h3>Istoria noastră</h3>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ScrollAnimation animateIn="fadeInLeft" >
                        <div className="history-item even-item clearfix">
                            <div className="icon-container">
                                <SvgPeople />
                            </div>
                            <div className="text-container">
                                <h4>Botez</h4>
                                <h5>18 Iul 2021</h5>
                                <p><em>Mărturia unui cuget curat</em></p>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInRight">
                        <div className="history-item odd-item clearfix">
                            <div className="icon-container">
                                <SvgPeople />
                            </div>
                            <div className="text-container">
                                <h4>Botez</h4>
                                <h5>20 Sept 2020</h5>
                                <p><em>&quot;un singur Domn, o singură credinţă, un singur botez&quot;</em> <small>Efeseni 4:5</small></p>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInLeft">
                        <div className="history-item even-item clearfix">
                            <div className="icon-container">
                                <SvgPeople />
                            </div>
                            <div className="text-container">
                                <h4>Botez</h4>
                                <h5>27 Aug 2017</h5>
                                <p><em>M-am hotărât să-L urmez pe Isus și înapoi eu nu voi da!</em></p>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInRight">
                        <div className="history-item odd-item clearfix">
                            <div className="icon-container">
                                <SvgPeople />
                            </div>
                            <div className="text-container">
                                <h4>Botez</h4>
                                <h5>Dec 2014</h5>
                                <p><em>&quot;Cine crede și se va boteza, va fi mântuit.&quot;</em> <small>Marcu 16:16</small></p>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInLeft">
                        <div className="history-item even-item clearfix">
                            <div className="icon-container">
                                <SvgPeople />
                            </div>
                            <div className="text-container">
                                <h4>Botez</h4>
                                <h5>19 Iun 2011</h5>
                                <p><em>Cele 7 minuni ale Cerului</em></p>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInRight">
                        <div className="history-item odd-item clearfix">
                            <div className="icon-container">
                                <SvgHome />
                            </div>
                            <div className="text-container">
                                <h4>Înființarea bisericii ARKA</h4>
                                <h5>12 Dec 2010</h5>
                                <p><em>&quot;...pe această piatră voi zidi Biserica Mea, şi porţile Locuinţei morţilor nu o vor birui.&quot;</em> <small>Matei 16:18</small></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    </section>
);

export default History;
