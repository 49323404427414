import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";

const Map: FC = () => {
    return (
        <div className="map-area">
            <a href="https://goo.gl/maps/SExzNdvhJGTTCYWL6" title="Contact" target="_blank" rel="noopener noreferrer">
                <StaticImage
                    src="../../images/arkacenter-gmap.jpg"
                    alt="Contact"
                    layout="fixed"
                    placeholder="blurred"
                    quality={100}
                    height={400}
                />
            </a>
        </div>
    );
};

export default Map;
