import React, { FC } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ScrollAnimation from "../ScrollAnimation";
import SvgRoyal from "@icon/icofont/icons/royal.svg";
import SvgOpposite from "@icon/icofont/icons/opposite.svg";
import SvgFootPrint from "@icon/icofont/icons/foot-print.svg";
import SvgHeart from "@icon/icofont/icons/heart.svg";

const AboutUs: FC = () => {
    return (
        <>
            <section className="section-pattern section-padding-100" id="despre-noi">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <ScrollAnimation>
                                <Link to="/adorare">
                                    <div className="arka-item text-center">
                                        <div className="icon-container"><SvgRoyal /></div>
                                        <div className="text-container">
                                            <h4>Adorare</h4>
                                            <p>Adorându-L pe Dumnezeu...</p>
                                        </div>
                                    </div>
                                </Link>
                            </ScrollAnimation>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <ScrollAnimation delay={1}>
                                <Link to="/rascumparare">
                                    <div className="arka-item text-center">
                                        <div className="icon-container"><SvgOpposite /></div>
                                        <div className="text-container">
                                            <h4>Răscumpărare</h4>
                                            <p>...răscumparând oameni...</p>
                                        </div>
                                    </div>
                                </Link>
                            </ScrollAnimation>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <ScrollAnimation delay={2}>
                                <Link to="/krestere">
                                    <div className="arka-item text-center">
                                        <div className="icon-container"><SvgFootPrint /></div>
                                        <div className="text-container">
                                            <h4>Kreștere</h4>
                                            <p>...crescând împreună...</p>
                                        </div>
                                    </div>
                                </Link>
                            </ScrollAnimation>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <ScrollAnimation delay={3}>
                                <Link to="/afectiune">
                                    <div className="arka-item text-center">
                                        <div className="icon-container"><SvgHeart /></div>
                                        <div className="text-container">
                                            <h4>Afecțiune &amp; Ajutorare</h4>
                                            <p>...slujindu-i pe ceilalți!</p>
                                        </div>
                                    </div>
                                </Link>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-us-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-5">
                            <ScrollAnimation animateIn="fadeInLeft">
                                <div className="image-bordered">
                                    <StaticImage
                                        src="../../images/despre-noi.jpg"
                                        alt="Despre noi"
                                    />
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="col-12 col-md-6">
                            <ScrollAnimation animateIn="fadeInRight" delay={1}>
                                <div className=" text-right-md">
                                    <h3>Despre noi</h3>
                                    <p>Într-o lume ca a generației noastre, în care toți aleargă după putere și faimă... noi am înțeles că DUMNEZEU urăște păcatul! DUMNEZEU pedepsește pacatul! Dar... ca și în vremea lui Noe, am capătat milă înaintea Lui. <strong>Și El a pregătit un plan de salvare - <span className="text-primary">ARKA</span>!</strong></p>
                                    <p>Printre valurile tumultoase ale acestei lumi, printre strigătele ei contradictorii, ARKA plutește înainte. În ciuda ambiguității cu care sunt priviți, ocupanții ei se dovedesc integri, consecvenți și temerari. Ei sunt oameni care Îl venerează și-L respectă pe Dumnezeu. Ei nu se lasă intimidați de decadența morală a civilizațiilor, ci își croiesc o cale sigură, o cale a Cuvântului lui Dumnezeu.</p>
                                    <p>ARKA este purtătoare de viață, salvare și speranță.</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <ScrollAnimation animateIn="fadeInUp">
                        <p className="lead text-center mt-5">Fie ca toți să navigăm sub același stindard și, de oriunde am fi,<br />să putem privi nestingherinți prin unica ei fereastră - <span className="text-primary">spre Cer</span>.</p>
                    </ScrollAnimation>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
