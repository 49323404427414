import React, { FC } from "react";
import ScrollAnimation from "../ScrollAnimation";

type PropsType = {
    animationVisible?: boolean;
}

const Program: FC<PropsType> = (props: PropsType) => (
    <section className="section-alternate section-padding-100-70">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <ScrollAnimation animateIn="fadeInUp" visible={props.animationVisible}>
                            <h3>Vino să ne închinăm împreună singurului Dumnezeu viu și adevărat!</h3>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" visible={props.animationVisible}>
                            <p>
                                <strong className="text-primary">Arka Center</strong><br />
                                Duminică, ora 17:00<br />
                                șoseaua Industrială nr. 2, Constanța
                            </p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Program;
